import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _113b11ff = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "" */))
const _d2622ebe = () => interopDefault(import('../pages/android/index.vue' /* webpackChunkName: "" */))
const _74f92d12 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "" */))
const _2bede03f = () => interopDefault(import('../pages/ios/index.vue' /* webpackChunkName: "" */))
const _e1107aa0 = () => interopDefault(import('../pages/recruit/index.vue' /* webpackChunkName: "" */))
const _0f530504 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _113b11ff,
    name: "about___vi"
  }, {
    path: "/android",
    component: _d2622ebe,
    name: "android___vi"
  }, {
    path: "/contact",
    component: _74f92d12,
    name: "contact___vi"
  }, {
    path: "/ios",
    component: _2bede03f,
    name: "ios___vi"
  }, {
    path: "/recruit",
    component: _e1107aa0,
    name: "recruit___vi"
  }, {
    path: "/en/",
    component: _0f530504,
    name: "index___en"
  }, {
    path: "/en/about",
    component: _113b11ff,
    name: "about___en"
  }, {
    path: "/en/android",
    component: _d2622ebe,
    name: "android___en"
  }, {
    path: "/en/contact",
    component: _74f92d12,
    name: "contact___en"
  }, {
    path: "/en/ios",
    component: _2bede03f,
    name: "ios___en"
  }, {
    path: "/en/recruit",
    component: _e1107aa0,
    name: "recruit___en"
  }, {
    path: "/ja/",
    component: _0f530504,
    name: "index___ja"
  }, {
    path: "/ja/about",
    component: _113b11ff,
    name: "about___ja"
  }, {
    path: "/ja/android",
    component: _d2622ebe,
    name: "android___ja"
  }, {
    path: "/ja/contact",
    component: _74f92d12,
    name: "contact___ja"
  }, {
    path: "/ja/ios",
    component: _2bede03f,
    name: "ios___ja"
  }, {
    path: "/ja/recruit",
    component: _e1107aa0,
    name: "recruit___ja"
  }, {
    path: "/zh/",
    component: _0f530504,
    name: "index___zh"
  }, {
    path: "/zh/about",
    component: _113b11ff,
    name: "about___zh"
  }, {
    path: "/zh/android",
    component: _d2622ebe,
    name: "android___zh"
  }, {
    path: "/zh/contact",
    component: _74f92d12,
    name: "contact___zh"
  }, {
    path: "/zh/ios",
    component: _2bede03f,
    name: "ios___zh"
  }, {
    path: "/zh/recruit",
    component: _e1107aa0,
    name: "recruit___zh"
  }, {
    path: "/",
    component: _0f530504,
    name: "index___vi"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
