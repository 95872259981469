
import ja from '../lang/ja_JP.js'
import zh from '../lang/zh-CN.js'
import vi from '../lang/vi_VI.js'
import en from '../lang/en_EN.js'

export default {
  // baseUrl: 'http://jobs-api.colorfulgroup.com', // 生产
  // baseUrl: 'http://192.168.8.198:8080',
  // baseUrl: 'http://34.124.184.12:8080',
  // baseUrl: 'https://saothantc.com',
  // socketUrl: '35.198.237.180:8081',
  // socketUrl: '192.168.8.198:8081',
  // baseUrl: 'http://vpn.cncf.online:30100',
  // ossUrl: 'https://zposs.qazxswq876.com/'
}

export const I18N = {
  locales: [
    {
      code: 'ja',
      iso: 'ja_JP',
      name: '日语'
    },
    {
      code: 'en',
      iso: 'en-US',
      name: '英语'
    },
    {
      code: 'vi',
      iso: 'vi',
      name: '越南语'
    },
    {
      code: 'zh',
      iso: 'zh-CN',
      name: '简体'
    },
  ],
  defaultLocale: 'vi',
  vueI18n: {
    fallbackLocale: 'vi',
    messages: { ja, zh, vi, en }
  }
}
